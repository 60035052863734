import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Datetime from "react-datetime";
import moment from "moment";
import MomentUtils from "../../../utils/MomentUtils";
import RequestUtils from "../../../utils/RequestUtils";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import "./Home.css";
import { Tag } from "antd";
import { Dropdown, InputGroup, DropdownButton } from "react-bootstrap";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import logo from "../../../TriggerCalc.png";

function Home() {
  /**  State Variables **/

  // State: hold a list of events

  let [metabolicRate, setMetabolicmetabolicRate] = useState("");

  let [daysAfter, setCurrentDaysAfter] = useState(null);

  let [emailModal, setEmailModal] = useState(false);

  let [comingSoonModal, setComingSoonModal] = useState(false);

  let [currentStartDateTime, setCurrentStartDateTime] = useState(
    MomentUtils.roundUp(moment(new Date()), "hour")
  );

  let [currentEndDateTime, setCurrentEndDateTime] = useState(
    MomentUtils.roundUp(moment(new Date()), "hour").add(1, "hour")
  );

  let [medication, setMedication] = useState("");

  let [dosage, setDosage] = useState([
    {
      value: "Select Dosage",
      label: "Units",
    },
  ]);

  let [selectedDossage, setSelectedDossage] = useState(null);

  let [results, setResults] = useState([{ results: "", color: "" }]);

  let [description, setDescription] = useState("");

  let [resultsModal, setResultsModal] = useState(false);

  let [confirmationModal, setConfirmationModal] = useState(false);

  const [state, handleSubmit] = useForm("xyyazrdp");

  function hideComingSoonModal() {
    setComingSoonModal(false);
  }
  function showComingSoonModal() {
    setComingSoonModal(true);
  }

  function hideEmailModal() {
    setEmailModal(false);
  }

  function showEmailModal() {
    setEmailModal(true);
  }

  function showConfirmation() {
    setConfirmationModal(true);
  }

  function hideConfirmation() {
    setConfirmationModal(false);
  }

  function showResults() {
    if (
      medication == "" ||
      metabolicRate == "" ||
      daysAfter == null ||
      selectedDossage == null
    ) {
      alert("Please fill out all fields");
      return;
    } else {
      setResultsModal(true);
    }
  }

  function hideResults() {
    setResultsModal(false);
  }

  function clearFields() {
    setCurrentDaysAfter(NaN);
    setMedication("");
    setMetabolicmetabolicRate("");
    setDosage([
      {
        value: "Select Dosage",
        label: "Units",
      },
    ]);
    setSelectedDossage(NaN);
  }
  // function clearResults() {
  //   setSelectedDossage("");
  //   setMedication("");
  //   setMetabolicmetabolicRate("");
  //   setCurrentDaysAfter(null);
  // }

  function dosageSetter(brand) {
    let dosages = null;
    if (brand == "OVIDREL") {
      dosages = [
        {
          value: "250",
          label: "MCG",
        },
      ];
    }
    if (brand == "NOVAREL") {
      dosages = [
        {
          value: "5000",
          label: "USP",
        },
        {
          value: "10000",
          label: "USP",
        },
      ];
    }
    if (brand == "PREGNYL") {
      dosages = [
        {
          value: "5000",
          label: "USP",
        },
        {
          value: "10000",
          label: "USP",
        },
      ];
    }
    if (brand == "null") {
      dosages = [
        {
          value: "Select Dosage",
          label: "Units",
        },
      ];
    }
    console.log(brand);
    setDosage(dosages);
    return dosages[0].value;
  }

  function calculate(e) {
    if (e != null) {
      e.preventDefault();
    }
    console.log(isNaN(parseInt(selectedDossage)));
    if (isNaN(parseInt(selectedDossage))) {
      setSelectedDossage(dosage[0].value);
      console.log(dosage[0].value);
    }
    if (medication == "OVIDREL") {
      if (metabolicRate == "SLOW") {
        if (daysAfter <= 11) {
          setResults([{ Text: "Not Reliable", Color: "red" }]);
          setDescription(
            "Based on the inputs provided, a pregnancy test will not be reliable at this time."
          );
        }
        if (daysAfter > 11 && daysAfter <= 14) {
          setResults([{ Text: "Varying", Color: "yellow" }]);
          setDescription(
            "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
          );
        }
        if (daysAfter >= 15) {
          setResults([{ Text: "Reliable!", Color: "green" }]);
          setDescription(
            "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
          );
        }
      }
      if (metabolicRate == "AVERAGE") {
        if (daysAfter <= 9) {
          setResults([{ Text: "Not Reliable", Color: "red" }]);
          setDescription(
            "Based on the inputs provided, a pregnancy test will not be reliable at this time."
          );
        }
        if (daysAfter >= 10 && daysAfter <= 12) {
          setResults([{ Text: "Varying", Color: "yellow" }]);
          setDescription(
            "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
          );
        }
        if (daysAfter >= 13) {
          setResults([{ Text: "Reliable!", Color: "green" }]);
          setDescription(
            "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
          );
        }
      }
      if (metabolicRate == "FAST") {
        if (daysAfter <= 7) {
          setResults([{ Text: "Not Reliable", Color: "red" }]);
          setDescription(
            "Based on the inputs provided, a pregnancy test will not be reliable at this time."
          );
        }
        if (daysAfter >= 8 && daysAfter <= 9) {
          setResults([{ Text: "Varying", Color: "yellow" }]);
          setDescription(
            "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
          );
        }
        if (daysAfter >= 10) {
          setResults([{ Text: "Reliable!", Color: "green" }]);
          setDescription(
            "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
          );
        }
      }
    }

    // SECOND OPTIONS
    if (medication == "NOVAREL" || medication == "PREGNYL") {
      if (parseInt(selectedDossage) == 5000) {
        if (metabolicRate == "SLOW") {
          if (daysAfter <= 10) {
            setResults([{ Text: "Not Reliable", Color: "red" }]);
            setDescription(
              "Based on the inputs provided, a pregnancy test will not be reliable at this time."
            );
          }
          if (daysAfter >= 11 && daysAfter <= 12) {
            setResults([{ Text: "Questionable", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, it is recommended to wait 1-2 more days for reliable results."
            );
          }
          if (daysAfter > 12 && daysAfter <= 14) {
            setResults([{ Text: "Varying", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
            );
          }
          if (daysAfter >= 15) {
            setResults([{ Text: "Reliable!", Color: "green" }]);
            setDescription(
              "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
            );
          }
        }
        if (metabolicRate == "AVERAGE") {
          if (daysAfter <= 9) {
            setResults([{ Text: "Not Reliable", Color: "red" }]);
            setDescription(
              "Based on the inputs provided, a pregnancy test will not be reliable at this time."
            );
          }
          if (daysAfter >= 10 && daysAfter <= 11) {
            setResults([{ Text: "Varying", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
            );
          }
          if (daysAfter >= 12) {
            setResults([{ Text: "Reliable!", Color: "green" }]);
            setDescription(
              "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
            );
          }
        }
        if (metabolicRate == "FAST") {
          if (daysAfter <= 7) {
            setResults([{ Text: "Not Reliable", Color: "red" }]);
            setDescription(
              "Based on the inputs provided, a pregnancy test will not be reliable at this time."
            );
          }
          if (daysAfter == 8) {
            setResults([{ Text: "Questionable", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, it is recommended to wait 1-2 more days for reliable results."
            );
          }
          if (daysAfter == 9) {
            setResults([{ Text: "Varying", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
            );
          }
          if (daysAfter >= 10) {
            setResults([{ Text: "Reliable!", Color: "green" }]);
            setDescription(
              "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
            );
          }
        }
      }
      if (parseInt(selectedDossage) == 10000) {
        if (metabolicRate == "SLOW") {
          if (daysAfter <= 12) {
            setResults([{ Text: "Not Reliable", Color: "red" }]);
            setDescription(
              "Based on the inputs provided, a pregnancy test will not be reliable at this time."
            );
          }
          if (daysAfter > 12 && daysAfter <= 14) {
            setResults([{ Text: "Varying", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
            );
          }
          if (daysAfter >= 15) {
            setResults([{ Text: "Reliable!", Color: "green" }]);
            setDescription(
              "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
            );
          }
        }
        if (metabolicRate == "AVERAGE") {
          if (daysAfter <= 10) {
            setResults([{ Text: "Not Reliable", Color: "red" }]);
            setDescription(
              "Based on the inputs provided, a pregnancy test will not be reliable at this time."
            );
          }
          if (daysAfter > 10 && daysAfter <= 12) {
            setResults([{ Text: "Varying", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
            );
          }
          if (daysAfter >= 13) {
            setResults([{ Text: "Reliable!", Color: "green" }]);
            setDescription(
              "Based on the inputs provided, all pregnancy tests on the market over 25MlU/mL should be reliable."
            );
          }
        }
        if (metabolicRate == "FAST") {
          if (daysAfter <= 9) {
            setResults([{ Text: "Not Reliable", Color: "red" }]);
            setDescription(
              "Based on the inputs provided, a pregnancy test will not be reliable at this time."
            );
          }
          if (daysAfter == 10) {
            setResults([{ Text: "Varying", Color: "yellow" }]);
            setDescription(
              "Based on the inputs provided, pregnancy tests with a 25MlU/mL sensitivity will be reliable, but a 6.5 MlU/mL will not be."
            );
          }
          if (daysAfter >= 11) {
            setResults([{ Text: "Reliable!", Color: "green" }]);
            setDescription(
              "Based on the inputs provided, all pregnancy tests on the market should be reliable."
            );
          }
        }
      }
    }
    showResults();
    console.log(daysAfter, metabolicRate, dosage, medication);
  }

  /** Helper Functions **/

  function test(e) {
    e.preventDefault();
    console.log("Test");
    debugger;
  }

  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src={logo}
              width="300"
              className="d-inline-block align-top mx-2"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav.Link className={"link mx-3"} href="/">
              Home
            </Nav.Link>
            <Nav.Link className={"link mx-3"} href="#target">
              About
            </Nav.Link>
            <Nav.Link className={"link mx-3"} href="#privacy">
              Privacy
            </Nav.Link>
            {/* <Nav.Link className={"link mx-3"} onClick={showComingSoonModal}>
          Ebook
        </Nav.Link> */}
            <Nav.Link className={"link mx-3"} onClick={showEmailModal}>
              Contact
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="diagonal-box">
        <div className="content my-auto align-middle">
          <Form
            className="calculator col-8 my-auto mx-auto"
            onSubmit={calculate}
          >
            <h2 className="title pb-2">Calculate Reliable Time</h2>
            <Form.Group className="mb-3">
              <Row lg>
                <Col className="pt-3">
                  <Form.Select
                    as="select"
                    value={medication}
                    onChange={(e) => {
   
                      setMedication(e.target.value);
                      let dos = dosageSetter(e.target.value);
                      setSelectedDossage(dos);
                    }}
                  >
                    <option value="null">Select a Value</option>
                    <option value="OVIDREL">Ovidrel</option>
                    <option value="NOVAREL">Novarel</option>
                    <option value="PREGNYL">Pregnyl</option>
                  </Form.Select>
                </Col>
                <Col md="auto" className="pt-3 pl-2">
                  <InputGroup className="">
                    <Form.Select
                      as="select"
                      value={selectedDossage}
                      onChange={(e) => {
                        setSelectedDossage(e.target.value);
                      }}
                    >
                      {dosage.map((dosage) => {
                        return <option>{dosage.value}</option>;
                      })}
                    </Form.Select>

                    <InputGroup.Text className="dark">
                      {dosage[0]["label"]}
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="Days"
                  value={daysAfter}
                  onChange={(e) => {
                    setCurrentDaysAfter(e.target.value);
                  }}
                />

                <InputGroup.Text className="dark">
                  from last trigger
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <InputGroup>
                <Form.Select
                  as="select"
                  value={metabolicRate}
                  onChange={(e) => {
                    setMetabolicmetabolicRate(e.target.value);
                  }}
                >
                  <option value="">Assumed Metabolic Rate</option>
                  <option value="SLOW">Slow - Worst Case Scenario</option>
                  <option value="AVERAGE">Average</option>
                  <option value="FAST">Fast - Best Case Scenario</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>

            <Button type="submit" variant="outline-dark">
              Calculate
            </Button>
            <Button
              variant="outline-dark"
              onClick={clearFields}
              className="mx-2"
            >
              Clear
            </Button>
          </Form>
        </div>
      </div>
      <Container className="ab pt-5 mt-3 pb-2">
        {/* <Container> */}
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <h2 className="text-center" id="target">
                About TriggerCalc
              </h2>
              <p className="par">
                With the numerous hormones and medications required during
                fertility procedures, women struggling with infertility do not
                go through the traditional conception and pregnancy process.
              </p>
              <p className="par">
                This calculator was developed to offer additional information to
                moms-to-be everywhere who are taking trigger shots to help
                improve chances of conception. Since no other online services
                provided an easy method to offer this type of information,
                TriggerCalc was created.
              </p>
              <p className="par">
                It is our hope that, using the pharmacology science behind the
                trigger shots, the calculator estimates will assist in reducing
                the stress and anxiety that often accompany the prospect of
                false-positive pregnancy tests.
              </p>
            </Col>

            {/* <Col md={{ span: 2, offset: 0 }} className="pt-5">      
          <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" style={{width: "150px", height: "240px"}} frameBorder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=triggercalc-20&language=en_US&marketplace=amazon&region=US&placement=1737265710&asins=1737265710&linkId=c7f501488599149fbecc1cb4f767dba1&show_border=true&link_opens_in_new_window=true"></iframe>
          </Col> 
               */}
          </Row>

        {/* </Container> */}
{/* 
        <a
          href="https://mira-fertility.pxf.io/c/3971726/1568679/12979"
          target="_top"
          id="1568679"
        >
          <img
            src="//a.impactradius-go.com/display-ad/12979-1568679"
            border="0"
            alt=""
            width="942"
            height="1410"
          />
        </a>
        <img
          height="0"
          width="0"
          src="https://imp.pxf.io/i/3971726/1568679/12979"
          style={{ position: "absolute", visibility: "hidden" }}
          border="0"
        /> */}
      </Container>
      {/* <iframe
        style={{ width: "550px", height: "40px" }}
        src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=triggercalc-20&language=en_US&marketplace=amazon&region=US&placement=1737265710&asins=1737265710&linkId=c7f501488599149fbecc1cb4f767dba1&show_border=true&link_opens_in_new_window=true"
      ></iframe> */}
      <Container className="ab pt-5 mt-3 pb-5">
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center">Information About The Calculator</h2>
          <p className="par">
            1. The information is based on the generally accepted figure that{" "}
            <a href="https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=a683e58a-63ea-44b8-a326-1a99a537bcf2&type=display">
              29 ± 6 hours is the half life range for subcutaneously injected
              HCG
            </a>{" "}
            (the active ingredient in all of the medications listed) intended
            for ovulation stimulation. This figure means that, on average,
            people will metabolize half of the remaining HCG in their body every
            29 hours. For some individuals, this figure will be closer to 35
            hours with a slow metabolic rate; for others, it will be closer to
            23 hours with a faster metabolic rate.{" "}
          </p>
          <p className="par">
            2. This information is intended as an <strong>estimate only</strong>
            . The metabolic rate for medications can differ drastically from
            person to person,{" "}
            <a href="https://genesight.com/genetic-insights/fast-slow-or-in-between-how-your-genes-affect-medication-success/">
              pending numerous factors
            </a>
            .
          </p>
          <p className="par">
            3. The sensitivity of pregnancy tests on the market vary
            significantly. If you are not sure what your pregnancy test
            sensitivity is,{" "}
            <a href="https://www.fairhavenhealth.com/hpt">this site offers</a>{" "}
            some helpful information as you interpret your calculator results.{" "}
          </p>
          <p className="par">
            <strong>
              4. This website is not intended to be a replacement for your
              medical provider’s advice, nor is it intended to be medical
              advice.
            </strong>
          </p>
        </Col>
      </Container>


      <Container className="ab pt-3 mt-3 pb-5" id="privacy">
  <Col md={{ span: 6, offset: 3 }}>
    <h2 className="text-center">Privacy Policy</h2>
    <p className="par">
      <strong>Introduction</strong><br/>
      TriggerCalc.com ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://triggercalc.com">TriggerCalc.com</a> ("Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Site.
    </p>
    <p className="par">
      <strong>Information We Collect</strong><br/>
      We may collect information about you in a variety of ways. The information we may collect on the Site includes:
      <ul>
        <li><strong>Usage Data:</strong> Information our servers automatically collect when you access the Site, such as your IP address, browser type, operating system, access times, and the pages you have viewed directly before and after accessing the Site.</li>
      </ul>
    </p>
    <p className="par">
      <strong>Use of Your Information</strong><br/>
      We may use the information we collect from you in the following ways:
      <ul>
        <li>To provide, operate, and maintain our Site.</li>
        <li>To understand and analyze how you use our Site.</li>
        <li>To develop new products, services, features, and functionality.</li>
      </ul>
    </p>
  </Col>
</Container>

<Container className="ab pt-3 mt-3 pb-5">
  <Col md={{ span: 6, offset: 3 }}>
    <h2 className="text-center">Terms of Service</h2>
    <p className="par">
      <strong>Acceptance of Terms</strong><br/>
      By accessing and using TriggerCalc.com ("Site"), you accept and agree to be bound by the terms and provision of this agreement. If you do not agree to abide by these terms, please do not use this Site.
    </p>
    <p className="par">
      <strong>Changes to Terms</strong><br/>
      We reserve the right to modify these terms at any time. You should check this page periodically for changes. By using this Site after we post any changes, you agree to accept those changes, whether or not you have reviewed them.
    </p>
    <p className="par">
      <strong>Use of the Site</strong><br/>
      You agree to use the Site only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Site. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within the Site.
    </p>
    <p className="par">
      <strong>Intellectual Property</strong><br/>
      All content on this Site, including but not limited to text, graphics, logos, images, and software, is the property of TriggerCalc.com and is protected by applicable copyright and trademark laws. You agree not to reproduce, distribute, or create derivative works based on any content without express written permission from us.
    </p>
  </Col>
</Container>


      <Modal show={resultsModal} onHide={hideResults} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center align-items-center">
            <InputGroup.Text
              className={"justify-content-center " + results[0]["Color"]}
            >
              {results[0]["Text"]}
            </InputGroup.Text>
            <br></br>
            <p className="px-3">{description}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideResults}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={emailModal} onHide={hideEmailModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center align-items-center">
            <Form onSubmit={handleSubmit}>
              <label htmlFor="email">Email Address</label>
              <Form.Control id="email" type="email" name="email" />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <br></br>
              <label htmlFor="email">Message</label>
              <Form.Control
                id="message"
                name="message"
                as="textarea"
                rows={3}
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <br></br>
              <Button
                type="submit"
                variant="outline-dark"
                disabled={state.submitting}
                onClick={hideEmailModal}
              >
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={comingSoonModal} onHide={hideComingSoonModal} centered>
        <Modal.Header closeButton>Page Under Construction</Modal.Header>

        <Modal.Body>
          <div className="justify-content-center align-items-center mx-3 my-3">
            <h4>Coming Soon!</h4>
          </div>
        </Modal.Body>
      </Modal>

      <footer className="page-footer font-small blue pt-4">
        <div className="container-fluid text-center text-md-left justify-content-center">
          <div className="row">
            <div className="col-md-6 mt-md-0 mt-3">
              <img
                src={logo}
                width="300"
                className="d-inline-block align-top mx-2"
                alt="React Bootstrap logo"
              />
            </div>

            <hr className="clearfix w-100 d-md-none pb-0" />

            <div className="col-lg-3 mt-4 mx-auto mb-4">
              <div className="list-unstyled">
                <li>
                  <a className="mx-3" href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a className="mx-3" href="#target">
                    About
                  </a>
                </li>
                <li>
                  <a className="mx-3" href="#privacy">
                    Privacy Policy
                  </a>
                </li>
                {/* <li><a className="mx-3" onClick={showComingSoonModal}>Ebook</a></li> */}
                <li>
                  <a className="mx-3" onClick={showEmailModal}>
                    Contact
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright text-center py-3">
          © 2023 Copyright TriggerCalc
        </div>
      </footer>
    </>
  );
}

export default Home;
